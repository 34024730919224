import { FC, SyntheticEvent, useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Loader, Stack } from 'rsuite'

import { useAuth } from '../../services/useAuth'
import BaseModal from './BaseModal'
import SafeTranslate from '../common/SafeTranslate'

import styles from '../../styles/EndSessionModal.module.less'

interface EndSessionModalProps {
  open: boolean
  cancel?: (event: SyntheticEvent) => void
  onConfirm: (event: SyntheticEvent) => void
  name?: string
  modalClassName?: string
}

const EndSessionModal: FC<EndSessionModalProps> = ({
  open,
  cancel,
  onConfirm,
  name,
  modalClassName = '',
}) => {
  const { t } = useTranslation('checkout')
  const { isImpersonated, isLoadingUser } = useAuth()
  const [nameInState, setNameInState] = useState('')

  useEffect(() => {
    if (name) {
      setNameInState(name)
    }
  }, [open])

  if (isLoadingUser) {
    return (
      <BaseModal
        isOpen={open}
        shouldDisplayCloseIcon={false}
        backdrop="static"
        modalClassName={modalClassName}
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Loader
            size="md"
            className={`${styles.loader} margin-bottom-spacer-tripple`}
          />
          <h5 className="margin-bottom-spacer">
            {t('Ending session')}
            ...
          </h5>
          <p>{t('Please wait while we securely complete the process')}</p>
        </Stack>
      </BaseModal>
    )
  }

  return (
    <BaseModal
      isOpen={open}
      closeModal={cancel}
      title={isImpersonated
        ? t('Are you ready to end session for {{name}}?', { name: nameInState })
        : t('Session Ended Successfully')}
      modalAction={isImpersonated ? onConfirm : cancel}
      actionButtonText={isImpersonated ? t('End session') : t('Back to marketplace')}
      closeButtonText={isImpersonated ? t('auth:Stay in session') : undefined}
      shouldDisplayCloseIcon={false}
      modalClassName={modalClassName}
      modalHeaderClassName={isImpersonated ? styles['modal-header'] : ''}
      backdrop="static"
    >
      {!isImpersonated ? (
        <SafeTranslate
          i18nKey="auth:The session for <b>{{name}}</b> has been successfully ended You’re now acting as your own superuser account"
          values={{
            name: nameInState,
          }}
          components={{
            b: <b />,
          }}
        />
      ) : undefined}
    </BaseModal>
  )
}

export default EndSessionModal
